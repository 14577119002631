import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Button from '../../../shared/components/button';
import './message-clients.scss';

const titleMessageClients =
  'Aviso importante: Migração do seu sistema Linx Farma Cloud!';
// const messageClients = `
// <p>Caro cliente, informamos que a Linx criou um plano de migração do sistema Linx Farma Cloud para o sistema Linx Big Farma,
// sem custo nenhum e com agendamento prévio.</p>
// <p>No dia <b>20 de fevereiro de 2025</b>, o Linx Farma Cloud será <b>desativado</b> completamente. É indispensável que você procure a franquia que te atende
// para agendar a sua migração antes da data limite acima.</p>
// <p>Essa será uma mudança importante que vai proporcionar uma evolução no seu sistema de gestão, ampliando funcionalidades e benefícios para o
// seu negócio. Conte com a gente!</p>`;
// const messageClients = ;

export const MessageClients = ({ loginIsOpen }) => {
  const [showModal, setShowModal] = useState(() => {
    return localStorage.getItem('showModalMessageClients') === 'true';
  });


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '700px',
      padding: '1.5rem',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.50)',
      display: 'block',
      padding: '1.2rem',
    },
  };

  const getMessage = () => {
    return `
    <p><b>Prezado Cliente</b>,</p> 
    <p>Gostaríamos de informar que ainda não identificamos o início do processo de migração do ERP Linx Farmacloud para o Linx Big. 
    Solicitamos que entre em contato urgentemente com sua franquia <b>`+ localStorage.getItem('franquia') + `</b> pelo telefone <b>` + localStorage.getItem('franquiaFone') + `</b>,
     falar com <b>`+ localStorage.getItem('franquiaContato') + `</b>, até o dia <b>05/02/2025</b>.</p>
    <p>Lembramos que o Linx Farmacloud será <b>descontinuado</b> em <b>20/02/2025</b>. 
    Portanto, é essencial que sua loja esteja migrada e sua equipe capacitada no novo ERP até essa data.</p>
    </br>
    <p>Caso não tenha sucesso no contato com a sua franquia contate o Executivo de Franquias Linx <b>`+ localStorage.getItem('franquiaExecutivo') + `</b>
     pelo email <b>`+ localStorage.getItem('franquiaExecutivoEmail') + `</b></p>
    <p>Se o processo de migração já está em andamento, favor desconsiderar esse lembrete.</p>`;
  }

  const handleClose = () => {
    setShowModal(false);
    localStorage.setItem('showModalMessageClients', 'false');
  };

  useEffect(() => {
    if (loginIsOpen) {
      setShowModal(true);
    }
  }, [loginIsOpen]);

  useEffect(() => {
    if (showModal) {
      localStorage.setItem('showModalMessageClients', 'true');
    }
  }, [showModal]);

  if (localStorage.getItem('franquia') !== 'undefined') {
    return (
      <div id='message-clients-container'>
        <div className='title-message'>
          <i className='material-icons'>info</i>

          <h3>{titleMessageClients}</h3>
        </div>

        <div
          className='message-text'
          dangerouslySetInnerHTML={{ __html: getMessage() }}
        />

        <ReactModal
          isOpen={showModal}
          shouldCloseOnEsc={false}
          style={customStyles}
          ariaHideApp={false}
        >
          <div className='modal-message-clients-container'>
            <div className='title-message'>
              <i className='material-icons'>info</i>

              <h3>{titleMessageClients}</h3>
            </div>

            <div
              className='message-text'
              dangerouslySetInnerHTML={{ __html: getMessage() }}
            />

            <div className='message-footer'>
              <Button className='btn btn-outline' onClick={handleClose}>
                OK
              </Button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  } else {
    return ('')
  }
};