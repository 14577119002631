import React from 'react';
import PdvInformation from '../../../container/pdv-information';
import { MessageClients } from './message-clients';
import './sub-footer.scss';

const SubFooter = (props) => (
  <div id='sub-footer'>
    <MessageClients loginIsOpen={false} />

    <div className='retailer-name'>
      <div className='div-info'>
        <i className='material-icons'>info</i>
      </div>
      <div className='div-retailer-name'>
        Razão social: {props.retailer?.retailer?.tradeName}
      </div>
      <PdvInformation className='div-pdv-information' />
    </div>
  </div>
);

export default SubFooter;
